import { hasMultiplePermissions, UserPermissionManager } from "./../../helper/comparisons";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { useAppSelector } from "../hooks";

export interface IRole {
  roleId?: string;
  roleName?: string;
  rolePermissions?: string;
  shopId?: string;
  defaultRole?: boolean;
  createdAt?: Date;
}

export interface UserPermissions {
  isShopOwner: boolean;
  permissions: string[];
}

interface IIntial {
  permissions: string[];
  isShopOwner: boolean;
  roleName?: string;
  roleId?: string;
}

const initialState: IIntial = {
  permissions: [],
  isShopOwner: false,
};

const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setRole(state, { payload }) {
      state.permissions = payload.rolePermissions?.split(",") || [];
      state.isShopOwner = payload.isShopOwner as boolean;
      state.roleId = payload.roleId;
    },
  },
});

export const userHasPermission = (permissions: string[]) => (state: RootState) =>
  state.roles.isShopOwner || hasMultiplePermissions(permissions, state.roles.permissions);

const getPermissions = (state: RootState) => state.roles.permissions;
const getIsShopOwner = (state: RootState) => state.roles.isShopOwner;

export const getUserPermissions = createSelector(
  [getPermissions, getIsShopOwner],
  (permissions, isShopOwner) => ({
    permissions,
    isShopOwner,
  })
);

export const useAccessControl = () => {
  const userPermissions = useAppSelector(getUserPermissions);
  const accessControl = new UserPermissionManager(userPermissions);

  return {
    hasPermission: accessControl.hasPermission.bind(accessControl),
    renderBasedOnPermission: accessControl.renderBasedOnPermission.bind(accessControl)
  };
};

export const { setRole } = roleSlice.actions;

export default roleSlice.reducer;
