import { UserPermissions } from "../app/slices/roles";

export type Permission = "VIEW_USER"
  | "MANAGE_USER"
  | "VIEW_SHOP"
  | "MANAGE_SHOP"
  | "VIEW_INVENTORY"
  | "MANAGE_INVENTORY"
  | "VIEW_ROLE"
  | "MANAGE_ROLE"
  | "VIEW_SUPPLY"
  | "MANAGE_SUPPLY"
  | "VIEW_SALE"
  | "MANAGE_SALE"
  | "VIEW_ALL_SALES"
  | "MANAGE_ALL_SALES"
  | "VIEW_PENDING_SALE"
  | "MANAGE_PENDING_SALE"
  | "VIEW_CASH_INFLOW"
  | "MANAGE_CASH_INFLOW"
  | "VIEW_EXPENDITURE"
  | "MANAGE_EXPENDITURE"
  | "VIEW_SUBSCRIPTION"
  | "MANAGE_SUBSCRIPTION"
  | "VIEW_DISCOUNT"
  | "MANAGE_DISCOUNT"
  | "VIEW_CUSTOMER"
  | "MANAGE_CUSTOMER"
  | "VIEW_SELL_ON_CREDIT"
  | "MANAGE_SELL_ON_CREDIT"
  | "MANAGE_REFUND";

export const isDateEqual = (firstDate: Date, secondDate: Date) => {
  if (
    firstDate.getDate() === secondDate.getDate() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getFullYear() === secondDate.getFullYear()
  ) {
    return true;
  }
  return false;
};

export class UserPermissionManager {
  private permissions: Set<string>;
  private isMerchant: boolean;

  constructor(userRolePermissions: string[] | UserPermissions, isMerchant = false) {
    this.permissions = new Set(
      Array.isArray(userRolePermissions)
        ? userRolePermissions
        : userRolePermissions.permissions
    );
    this.isMerchant = Array.isArray(userRolePermissions)
      ? isMerchant : userRolePermissions.isShopOwner;
  }

  hasPermission(permission: Permission | Permission[]): boolean {
    const permissionsArray = Array.isArray(permission) ? permission : [permission];
    return this.isMerchant || permissionsArray.some((perm) => this.permissions.has(perm));
  }

  renderBasedOnPermission(
    permission: Permission | Permission[],
    hasPermissionContent: any,
    noPermissionContent: any = null
  ): any {
    return this.hasPermission(permission) ? hasPermissionContent : noPermissionContent;
  }
}

export const hasPermission = (
  permission: Permission | Permission[],
  userRolePermissions: string[] | UserPermissions
) => {
  const permissionsArray = Array.isArray(permission) ? permission : [permission];

  if (Array.isArray(userRolePermissions)) {
    return permissionsArray.some((perm) => userRolePermissions.includes(perm));
  }

  return userRolePermissions.isShopOwner ||
    permissionsArray.some((perm) => userRolePermissions.permissions.includes(perm));
};

export const hasAnyPermission = (
  permissions: string[] | undefined,
  userRolePermissions: string[] | UserPermissions
) => {
  let permissionList = userRolePermissions as string[];
  const trimmedPermissions = permissions?.map((permission) => permission.trim());

  if (!Array.isArray(userRolePermissions)) {
    if (userRolePermissions.isShopOwner) {
      return true;
    }

    permissionList = userRolePermissions.permissions;
  }

  if (trimmedPermissions?.length === 0) {
    return true;
  }

  for (let i = 0; i < permissionList.length; i++) {
    if (trimmedPermissions?.includes(permissionList[i])) {
      return true;
    }
  }

  return false;
};

export const hasMultiplePermissions = (
  permissions: string[],
  userRolePermissions: string[] | UserPermissions
) => {
  let permissionList = userRolePermissions as string[];

  if (!Array.isArray(userRolePermissions)) {
    if (userRolePermissions.isShopOwner) {
      return true;
    }

    permissionList = userRolePermissions.permissions;
  }

  for (let i = 0; i < permissionList.length; i++) {
    if (permissions.includes(permissionList[i])) {
      continue;
    }

    return false;
  }

  return true;
};

export const syncTotalTableCount = (
  syncTableUpdateCount: { [key: string]: number },
  tables: any[]
) => {
  return tables.reduce((prevCount: number, tableModel: any) => {
    return prevCount + (syncTableUpdateCount[tableModel] ?? 0);
  }, 0);
};

export const checkPermission = (permission: string, userPermissions: UserPermissions) => {
  const result = {
    isMerchant: false,
    manageInventory: false,
    viewInventory: false,
    manageSale: false,
    viewSale: false,
    viewAllSales: false,
    manageAllSales: false,
    viewCashInflow: false,
    manageCashInflow: false,
    viewExpenditure: false,
    manageExpenditure: false,
    manageUser: false,
    viewCustomer: false,
    manageCustomer: false,
  };

  if (userPermissions?.isShopOwner) {
    result.isMerchant = true;
    return result;
  }

  switch (permission) {
    case "MANAGE_INVENTORY":
      result.manageInventory = hasPermission(permission, userPermissions);
      break;
    case "VIEW_INVENTORY":
      result.viewInventory = hasPermission(permission, userPermissions);
      break;
    case "MANAGE_SALE":
      result.manageSale = hasPermission(permission, userPermissions);
      break;
    case "VIEW_SALE":
      result.viewSale = hasPermission(permission, userPermissions);
      break;
    case "VIEW_ALL_SALES":
      result.viewAllSales = hasPermission(permission, userPermissions);
      break;
    case "MANAGE_ALL_SALES":
      result.manageAllSales = hasPermission(permission, userPermissions);
      break;
    case "VIEW_CASH_INFLOW":
      result.viewCashInflow = hasPermission(permission, userPermissions);
      break;
    case "MANAGE_CASH_INFLOW":
      result.manageCashInflow = hasPermission(permission, userPermissions);
      break;
    case "VIEW_EXPENDITURE":
      result.viewExpenditure = hasPermission(permission, userPermissions);
      break;
    case "MANAGE_EXPENDITURE":
      result.manageExpenditure = hasPermission(permission, userPermissions);
      break;
    case "MANAGE_USER":
      result.manageUser = hasPermission(permission, userPermissions);
      break;
    case "VIEW_CUSTOMER":
      result.viewCustomer = hasPermission(permission, userPermissions);
      break;
    case "MANAGE_CUSTOMER":
      result.manageCustomer = hasPermission(permission, userPermissions);
      break;
    default:
      break;
  }

  return result;
};
