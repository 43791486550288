/* eslint-disable comma-dangle */
export const formatName = (name?: string, splitBy: string = " ") => {
  if (!name) {
    return "";
  }

  const words = name.toLowerCase().split(splitBy);
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(" ");
};

export const formatPrice = (
  amount: number,
  noCurrency: boolean = false,
  currency?: string
): string | null => {
  if (!amount) {
    return "0";
  }
  if (amount === 0) {
    return null;
  }
  const formattedAmount = amount?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  if (noCurrency) {
    return formattedAmount;
  } else {
    return `${currency ?? "₦ "}${formattedAmount}`;
  }
};

export const validateInputNum = (setNumVal: Function, val: string | number) => {
  const newVal = val.toString().replace(/[^\d.]/g, "");

  if (newVal === "") {
    setNumVal("");
    return;
  }
  if (!isNaN(parseFloat(newVal))) {
    setNumVal(Number(newVal));
  }
};

export const convertToNumber = (number: string) => {
  const cleanString = number.replace(/[,\s]/g, "");

  const result = parseFloat(cleanString);

  if (!isNaN(result)) {
    return result;
  } else {
    return cleanString;
  }
};

export const trimValue = (value: string) => {
  return value.trim();
};

export const getInitials = (name?: string) => {
  if (name) {
    const words = name.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase());

    return initials.length > 2 ? `${initials[0]} ${initials[2]}` : initials.join("");
  }
  return "";
};

export const formatNumber = (value: string | number | undefined,
  decimalPlaces: number = 2): string => {
  if (!value) {
    return "0";
  }

  const value_ = String(Number(Number(value).toFixed(decimalPlaces)));

  if (value_.split(".")[1]?.length === 1) {
    return `${value_}0`;
  }

  return value_;
};
