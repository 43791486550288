import Debt from "../../assets/DebtNav.svg";
import DebtClicked from "../../assets/DebtNavClicked.svg";
import orderIcon from "../../assets/Orders.svg";
import orderIconA from "../../assets/OrdersA.svg";
import OverviewClicked from "../../assets/OverviewClicked.svg";
import ProductsClicked from "../../assets/ProductClicked.svg";
import SalesClicked from "../../assets/SalesClicked.svg";
import settingIcon from "../../assets/Setting.svg";
import Stock from "../../assets/Stock.svg";
import StockClicked from "../../assets/StockClicked.svg";
import Overview from "../../assets/overview.svg";
import Products from "../../assets/products.svg";
import Sales from "../../assets/sales.svg";
import SettingsClicked from "../../assets/settingsClicked.svg";
import StaffClicked from "../../assets/staff-clicked.svg";
import Staff from "../../assets/staff.svg";
import SuppliersClicked from "../../assets/suppliers-clicked.svg";
import Suppliers from "../../assets/suppliers.svg";
import Invoice from "../../assets/invoice.svg";
import InvoiceActive from "../../assets/InvoiceActive.svg";

export interface ISubRoute {
  name: string;
  icon: string;
  path: string;
  activeIcon: string;
  allowedRoles: string[];
  subRoutes?: {
    path?: string;
    name?: string;
    allowedRoles?: string[];
  }[];
}

const defaultSidebarItems: ISubRoute[] = [
  {
    name: "Overview",
    icon: Overview,
    path: "/dashboard",
    activeIcon: OverviewClicked,
    allowedRoles: [],
    subRoutes: [],
  },
  {
    name: "Products",
    icon: Products,
    path: "/product",
    activeIcon: ProductsClicked,
    allowedRoles: ["MANAGE_INVENTORY", "VIEW_INVENTORY"],
    subRoutes: [
      {
        name: "Add a New Product",
        path: "/product/add",
        allowedRoles: ["MANAGE_INVENTORY"],
      },
      {
        name: "Products",
        path: "/product",
        allowedRoles: ["MANAGE_INVENTORY", "VIEW_INVENTORY"],
      },
      // {
      //   name: "Batches",
      //   path: "/product/batches",
      // },
      {
        name: "Product Transfer",
        path: "/product/transfer",
        allowedRoles: ["MANAGE_INVENTORY"],
      },
      //   {
      //     name: "Product Settings",
      //     path: "/product/settings",
      //   },
    ],
  },
  {
    name: "Sales",
    icon: Sales,
    path: "/sales",
    activeIcon: SalesClicked,
    allowedRoles: ["MANAGE_SALE", "VIEW_SALE", "VIEW_ALL_SALES", "MANAGE_ALL_SALES"],
    subRoutes: [
      {
        name: "Record New Sales",
        path: "/sales/new",
        allowedRoles: ["MANAGE_SALE", "VIEW_SALE", "VIEW_ALL_SALES", "MANAGE_ALL_SALES"],
      },
      {
        name: "Sales Overview",
        path: "/sales",
        allowedRoles: ["MANAGE_SALE", "VIEW_SALE", "VIEW_ALL_SALES", "MANAGE_ALL_SALES"],
      },
      {
        name: "Income & Expenses",
        path: "/expenses",
        allowedRoles: [
          "VIEW_CASH_INFLOW",
          "MANAGE_CASH_INFLOW",
          "VIEW_EXPENDITURE",
          "MANAGE_EXPENDITURE",
        ],
      },
      // {
      //   name: "Sales Settings",
      //   path: "/sales/settings",
      //   allowedRoles: [
      //     "VIEW_CASH_INFLOW",
      //     "MANAGE_CASH_INFLOW",
      //     "VIEW_EXPENDITURE",
      //     "MANAGE_EXPENDITURE",
      //   ],
      // },
    ],
  },
  {
    name: "Adjust Stock",
    path: "/stock-adjustment",
    icon: Stock,
    activeIcon: StockClicked,
    allowedRoles: ["VIEW_INVENTORY"],
    subRoutes: [
      {
        name: "New Adjustment",
        path: "/stock-adjustment",
        allowedRoles: ["MANAGE_INVENTORY"],
      },
      {
        name: "Adjustment History",
        path: "/stock-adjustment/history",
        allowedRoles: ["VIEW_INVENTORY"],
      },
      {
        name: "Stock Status & Pricing",
        path: "/stock-adjustment/stock-status",
        allowedRoles: ["MANAGE_INVENTORY"],
      },
    ],
  },
  // {
  //   name: "Wallet",
  //   icon: WalletIcon,
  //   path: "#",
  //   activeIcon: ActiveWalletIcon,
  //   allowedRoles: ["VIEW_CUSTOMER", "MANAGE_CUSTOMER"],
  //   subRoutes: [],
  // },
  {
    name: "Debt Book",
    icon: Debt,
    path: "/customers",
    activeIcon: DebtClicked,
    allowedRoles: ["VIEW_CUSTOMER", "MANAGE_CUSTOMER"],
    subRoutes: [
      // {
      //   name: "Debt Receivables",
      //   path: "/customers",
      // },
      // {
      //   name: "Debt Payables",
      //   path: "/customers",
      // },
      // {
      //   name: "My customers",
      //   path: "/customers",
      // },
      // {
      //   name: "Debt Settings",
      //   path: "/customers",
      // },
    ],
  },
  {
    name: "Orders",
    icon: orderIcon,
    path: "/kitchen-orders",
    activeIcon: orderIconA,
    allowedRoles: ["VIEW_ALL_SALES", "MANAGE_ALL_SALES", "VIEW_SALE", "MANAGE_SALE"],
    subRoutes: [
      // {
      //   name: "Timart Shop",
      //   path: "/kitchen-orders",
      // },
      // {
      //   name: "Custom Website",
      //   path: "/kitchen-orders",
      // },
      // {
      //   name: "Table Orders",
      //   path: "/kitchen-orders",
      // },
      // {
      //   name: "Order Settings",
      //   path: "/kitchen-orders",
      // },
    ],
  },
  {
    name: "Invoice",
    icon: Invoice,
    path: "/invoices",
    activeIcon: InvoiceActive,
    allowedRoles: ["VIEW_SALE", "MANAGE_SALE", "VIEW_ALL_SALES", "MANAGE_ALL_SALES"],
    subRoutes: [
      {
        name: "Create New Invoice",
        path: "/invoices/new",
        allowedRoles: ["VIEW_SALE", "MANAGE_SALE", "VIEW_ALL_SALES", "MANAGE_ALL_SALES"],
      },
      {
        name: "Invoices Overview",
        path: "/invoices",
        allowedRoles: ["VIEW_SALE", "MANAGE_SALE", "VIEW_ALL_SALES", "MANAGE_ALL_SALES"],
      },
    ],
  },
  {
    name: "Suppliers",
    path: "/suppliers",
    icon: Suppliers,
    activeIcon: SuppliersClicked,
    allowedRoles: ["MANAGE_INVENTORY"],
    subRoutes: [
      // {
      //   name: "Record Supplies",
      //   path: "/suppliers/new",
      // },
      // {
      //   name: "Supplies Overview",
      //   path: "/suppliers",
      // },
      // {
      //   name: "My Suppliers/Vendors",
      //   path: "/suppliers",
      // },
    ],
  },
  {
    name: "My Staff",
    path: "/staffs",
    icon: Staff,
    activeIcon: StaffClicked,
    allowedRoles: ["VIEW_USER", "MANAGE_USER"],
    subRoutes: [],
  },
  // {
  //   name: "Business Report",
  //   path: "#",
  //   icon: BReposidertIcon,
  //   activeIcon: ReportActive,
  //   allowedRoles: [],
  //   subRoutes: [],
  // },
  {
    name: "Settings",
    icon: settingIcon,
    path: "/settings",
    activeIcon: SettingsClicked,
    allowedRoles: [],
    subRoutes: [],
  },
  // {
  //   name: "Help",
  //   icon: HelpIcon,
  //   path: "#",
  //   activeIcon: HelpClicked,
  //   allowedRoles: ["VIEW_CUSTOMER", "MANAGE_CUSTOMER"],
  //   subRoutes: [],
  // },
];

export const sidebarDetails = defaultSidebarItems;
